<template>
  <v-container fluid>
    <v-row>

      <v-col>
        <v-row justify="space-between" >
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2470.283457454264!2d5.677057615978197!3d51.74614010103975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c7036d71b4d299%3A0xa24cd763feebfe63!2sBrouwershof%2021%2C%205375%20AD%20Reek!5e0!3m2!1snl!2snl!4v1575382775642!5m2!1snl!2snl" width="1900" height="500" frameborder="0" style="border:0;"></iframe>
        </v-row>
      </v-col>

      <v-col>
        <v-layout column align-center="">

          <v-row align-content="center"  justify="center">
            <v-icon color="primary" size="75">
              home
            </v-icon>
          </v-row>

          <h3>Adres</h3>
          <br />
          <span class="grey--text">Brouwershof 21
            <br />
            5375 AD Reek
          </span>
          <br/>
          <span class="grey--text">
            KvK Nr : 17134140
          </span>

          <span class="grey--text">
            Btw id : NL001763841B39
          </span>

        </v-layout>
      </v-col>

      <v-col>
        <v-layout column align-center="">

        <v-row justify="center">
          <v-icon color="primary" size="75">
            phone
          </v-icon>
        </v-row>

        <h3>Telefoon</h3>
        <br />
        <p class="grey--text">06 - 12 99 92 24</p>

        </v-layout>
      </v-col>

      <v-col>
        <v-layout column align-center="">
        <v-row justify="center">
          <v-icon color="primary" size="75">
            email
          </v-icon>
        </v-row>
        <h3>E-mail</h3>
        <br />

        <a class="grey--text" href="mailto:info@tonbierman.nl">info@tonbierman.nl</a>

        </v-layout>
      </v-col>

      <v-col>
        <v-layout column align-center="">

        <v-row justify="center">
          <v-icon color="primary" size="75">
            access_time
          </v-icon>
        </v-row>

        <h3>Werktijden</h3>
        <br />
        <span class="grey--text">Ma-vrij: 07:30 tot 17:00 uur</span>
        <span class="grey--text">(Overige tijden in overleg)</span>
        <span class="grey--text">Telefonisch ook bereikbaar in de avonduren</span>

        </v-layout>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  export default {
  }
</script>
